import { DateTime } from 'luxon'
import { ExploreProgram } from '@citruscamps/citrus-client'
import { EventFilterProps } from '../../../hooks/useFetchEvents'
import { DateSelect } from '../../DateSelect/DateSelect'
import { useFetchWeek } from '../hooks/useFetchWeek'

interface IProps {
  program: ExploreProgram
  filter: Partial<EventFilterProps>
  onChange: (date: DateTime) => Promise<void> | void
}

export const EventDateSelect = ({ program, filter, onChange: handleChange }: IProps) => {
  const { data, isLoading } = useFetchWeek({
    programId: program.id,
    filter,
  })

  return (
    <DateSelect
      value={new Date(filter.scheduled?.gte || new Date())}
      disabledDay={(day: DateTime) => {
        const hasEvent = data.some(
          (d) => d.has_event && DateTime.fromFormat(d.iso_date, 'yyyy-MM-dd').hasSame(day, 'day'),
        )
        return isLoading || !hasEvent
      }}
      onChange={handleChange}
    />
  )
}
