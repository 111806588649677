import { sanitize as sanitizeHtml } from 'isomorphic-dompurify'

const defaultAllowTags = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'h7',
  'h8',
  'br',
  'b',
  'i',
  'strong',
  'em',
  'a',
  'pre',
  'code',
  'img',
  'tt',
  'div',
  'ins',
  'del',
  'sup',
  'sub',
  'p',
  'ol',
  'ul',
  'table',
  'thead',
  'tbody',
  'tfoot',
  'blockquote',
  'dl',
  'dt',
  'dd',
  'kbd',
  'q',
  'samp',
  'var',
  'hr',
  'ruby',
  'rt',
  'rp',
  'li',
  'tr',
  'td',
  'th',
  's',
  'strike',
  'summary',
  'details',
  'caption',
  'figure',
  'figcaption',
  'abbr',
  'bdo',
  'cite',
  'dfn',
  'mark',
  'small',
  'span',
  'time',
  'wbr',
]

interface IOptions {
  allowedTags?: string[]
}

export function sanitize(value?: string, { allowedTags }: IOptions = {}): string {
  return value
    ? sanitizeHtml(value, {
        ALLOWED_TAGS: allowedTags || defaultAllowTags,
        KEEP_CONTENT: true,
      })
    : ''
}
