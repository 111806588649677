import { faCalendarAlt, faListUl } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import {
  ExploreProgram,
  Gender,
  ProgramEventType,
  QuickFilter,
  Venue,
} from '@citruscamps/citrus-client'
import { EventFilterProps } from '../../../hooks/useFetchEvents'
import { findMatchVenue } from '../../../utils/findMatchVenue'
import { FeatureFlag } from '../../FeatureFlag/FeatureFlag'
import { useFetchInfiniteQuickFilters } from '../hooks/useFetchQuickFilters'
import { EventDateSelect } from './EventDateSelect'
import { FilterSelect } from './FilterSelect'
import { QuickFilterSelect } from './QuickFilterSelect'
import { VenueSelect } from './VenueSelect'

const DefaultSelects: EventFilterProps = {
  type: 'camp',
  place_name: undefined,
  age: undefined,
  gender: undefined,
  is_online_event: undefined,
  tags: [],
  scheduled: {},
}

interface IProps {
  values?: EventFilterProps
  program: ExploreProgram
  hasOnline?: boolean
  venues: Venue[]
  view?: 'list' | 'calendar'
  onChange: (values: EventFilterProps) => Promise<void> | void
  handleSetView?: (value: 'list' | 'calendar') => void
}

export const EventFilters = ({
  values = DefaultSelects,
  program,
  view,
  venues,
  hasOnline,
  onChange: handleChange,
  handleSetView,
}: IProps) => {
  const minAge: number = 7
  const maxAge: number = 18
  const { data: quickFilters, isLoading: isLoadingFilters } = useFetchInfiniteQuickFilters({
    programId: program.id,
    fetchAll: true,
  })
  const isFiltering =
    typeof values.age !== 'undefined' ||
    typeof values.gender !== 'undefined' ||
    typeof values.place_name !== 'undefined' ||
    !!values.is_online_event
  const venue = findMatchVenue(values.place_name, venues)

  return (
    <>
      <div className="row mt-lg--3 mt-5 mb-3">
        <div className="col">
          <div
            className="d-flex flex-lg-row flex-md-row flex-column px-3"
            style={{ marginTop: '-2.5rem' }}
          >
            <h2 className="py-1">
              Upcoming in
              <span className="sr-only">
                {' '}
                {values?.is_online_event || hasOnline
                  ? 'Online Events'
                  : venue
                  ? [(venue?.city, venue?.region_short_code.split('-').pop()?.toUpperCase())]
                      .filter((a) => !!a)
                      .join(', ')
                  : 'All locations'}
              </span>
            </h2>
            <div className="mt-0 mb-2 mb-lg-auto py-1" style={{ flex: 1 }}>
              <VenueSelect
                instanceId="venue-select"
                values={values}
                hasOnline={hasOnline}
                venues={venues}
                program={program}
                onChange={handleChange}
              />
            </div>
            {view && handleSetView && (
              <span className="pb-3 text-center ml-lg-auto ml-md-auto pt-lg-2 pt-md-2">
                <div className="w-100 w-sm-auto">
                  <button
                    type="button"
                    className={`ml-1 btn ${view === 'list' ? 'btn-primary' : 'btn-link'}`}
                    onClick={() => {
                      handleSetView('list')
                    }}
                  >
                    <FontAwesomeIcon icon={faListUl} />
                    <span className="sr-only">View in list mode</span>
                  </button>
                  <span className="small py-2 mx-2">|</span>
                  <button
                    type="button"
                    className={`btn ${view === 'calendar' ? ' btn-primary' : 'btn-link'}`}
                    onClick={async () => {
                      handleSetView('calendar')
                    }}
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <span className="sr-only">View in calendar mode</span>
                  </button>
                </div>
              </span>
            )}
          </div>
        </div>
      </div>
      <FilterPrimarySelect
        isLoadingFilters={isLoadingFilters}
        program={program}
        quickFilters={quickFilters}
        values={values}
        venue={venue}
        view={view}
        onChange={handleChange}
      />
      <div
        className="filters w-100 mx-auto mt-3"
        style={{ marginTop: quickFilters?.length ? '1rem' : '-2rem' }}
      >
        <div className="col d-sm-flex ">
          <div className="text-center pb-3">
            <FeatureFlag
              flagKey={['sessions_enabled', 'memberships_enabled']}
              checkAll={(flags) => flags.sessions_enabled || flags.memberships_enabled}
            >
              <div className="d-inline-block rounded bg-white mr-lg-2 w-sm-auto m-0 p-2 pl-lg-0">
                <FilterSelect
                  instanceId="type-select"
                  value={values.type}
                  options={[
                    { value: 'camp', label: 'Camps' },
                    { value: 'session', label: 'Sessions' },
                  ]}
                  program={program}
                  onChange={(value: ProgramEventType) =>
                    handleChange({ ...values, tags: [], type: value })
                  }
                />
              </div>
            </FeatureFlag>
            <div className="d-inline-block rounded bg-white mr-lg-2 w-sm-auto m-0 p-2">
              <FilterSelect
                instanceId="age-select"
                value={values.age}
                placeholder="All ages"
                options={[
                  ...(values.age ? [{ value: undefined, label: 'All ages' }] : []),
                  ...Array(maxAge - minAge + 1)
                    .fill(null)
                    .map((_, idx) => ({ value: minAge + idx, label: `${minAge + idx} years old` })),
                ]}
                program={program}
                onChange={(value?: number) => handleChange({ ...values, age: value })}
              />
            </div>
            <div className={`d-inline-block rounded bg-white mr-lg-2 w-sm-auto m-0 p-2`}>
              <FilterSelect
                instanceId="gender-select"
                value={values.gender}
                placeholder="All genders"
                options={[
                  ...(values.gender ? [{ value: undefined, label: 'All genders' }] : []),
                  { value: 'male', label: 'Boys' },
                  { value: 'female', label: 'Girls' },
                ]}
                program={program}
                onChange={(value?: Gender) => handleChange({ ...values, gender: value })}
              />
            </div>
            {(isFiltering || values.tags) && (
              <button
                className="btn btn-link m-2"
                onClick={() => {
                  handleChange({
                    ...DefaultSelects,
                    type: values.type,
                    scheduled: {
                      gte: values.scheduled?.gte ? new Date(values.scheduled.gte) : undefined,
                      lte: values.scheduled?.lte ? new Date(values.scheduled.lte) : undefined,
                    },
                  })
                }}
              >
                Clear filters
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

interface IFilterPrimarySelectProps
  extends Pick<IProps, 'view' | 'program' | 'onChange'>,
    Pick<Required<IProps>, 'values'> {
  venue?: Venue
  quickFilters: QuickFilter[]
  isLoadingFilters: boolean
}

const FilterPrimarySelect = (props: IFilterPrimarySelectProps) => {
  const {
    isLoadingFilters,
    program,
    quickFilters,
    values,
    venue,
    view,
    onChange: handleChange,
  } = props
  if (view === 'calendar') {
    return (
      <div className="col mt-sm-3">
        <EventDateSelect
          program={program}
          filter={values}
          onChange={(date: DateTime) => {
            const gte = date.startOf('day').toJSDate()
            const lte = date.endOf('day').toJSDate()
            handleChange({ ...values, scheduled: { gte, lte } })
          }}
        />
      </div>
    )
  }
  if (!!quickFilters?.length || isLoadingFilters) {
    return (
      <QuickFilterSelect
        values={values}
        venue={venue}
        quickFilters={quickFilters}
        isLoading={isLoadingFilters}
        onChange={handleChange}
      />
    )
  }
  return null
}
