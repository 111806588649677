import { ExplorePreferences } from '@citruscamps/citrus-client'
import { sanitize } from '../../../utils/sanitize'

interface IProps {
  preferences: ExplorePreferences | undefined
}

export const StoreFrontText = ({ preferences }: IProps) => {
  return (
    <div className="row justify-content-center mt-2 text-center">
      <div className="col-md-10 col-lg-8">
        <div
          className="p-3"
          style={{ marginBottom: '-1rem' }}
          dangerouslySetInnerHTML={{
            __html: sanitize(preferences?.store_front_text),
          }}
        ></div>
      </div>
    </div>
  )
}
