import { useQuery } from '@tanstack/react-query'
import { ErrorResponse, Venue } from '@citruscamps/citrus-client'
import { HttpClient } from '../../../utils/http'
import { ProgramVenueSummary } from '../../../pages/api/venues/[program_id]'
import { generateItemKey } from '../../../utils/key-generator'

interface IProps {
  programId?: string
  enabled?: boolean
  type: 'event' | 'appointment'
}

interface FetchVenues {
  data: Venue[]
  hasOnline: boolean
  error?: ErrorResponse | null
  isError: boolean
  isLoading: boolean
}

export const useFetchVenues = ({ programId, enabled = true, type }: IProps): FetchVenues => {
  const queryKeys = generateItemKey({
    type: 'venue',
    id: programId,
  })
  const {
    isError,
    data: resp,
    error,
    isInitialLoading: isLoading,
  } = useQuery<ProgramVenueSummary, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new HttpClient({ baseUrl: '' })
      return await client.get(`/api/venues/${programId}?type=${type}`, { signal })
    },
    {
      enabled: !!programId && !!enabled,
      keepPreviousData: true,
    },
  )

  return {
    data: resp?.venues || [],
    hasOnline: !!resp?.online_enabled,
    error: error || undefined,
    isError,
    isLoading,
  }
}
