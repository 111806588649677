import React from 'react'
import Select, {
  ControlProps,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
  OptionProps,
  PlaceholderProps,
  Props,
  SingleValue,
  SingleValueProps,
  StylesConfig,
} from 'react-select'
import { ExplorePreferences, ExploreProgram } from '@citruscamps/citrus-client'
import {
  addAlpha,
  DefaultDark,
  DefaultLight,
  getSafeBrandColor,
} from '../../../utils/branding-utils'

export const getCustomStyles = (
  preferences?: ExplorePreferences,
): Partial<StylesConfig<ValueOption, any, any>> => {
  const brand_accent_color: string = getSafeBrandColor(preferences)
  return {
    control: (base: CSSObjectWithLabel, props: ControlProps<ValueOption, any, any>): any => ({
      ...base,
      borderBottomWidth: 1,
      borderColor: brand_accent_color,
      borderRadius: '3.125rem',
      boxShadow: 'none',
      fontWeight: 'bold',
      minWidth: 120,
      ...(props.hasValue
        ? {
            color: '#ffffff',
            backgroundColor: brand_accent_color,
            '&:hover': {
              backgroundColor: addAlpha(brand_accent_color, 0.95),
            },
          }
        : {
            color: brand_accent_color,
            backgroundColor: 'transparent',
            '&:hover': {
              color: '#ffffff',
              backgroundColor: brand_accent_color,
            },
          }),
    }),
    option: (base: CSSObjectWithLabel, props: OptionProps<any, any, any>) => ({
      ...base,
      backgroundColor: props.isSelected ? brand_accent_color : 'transparent',
      '&:hover': {
        backgroundColor: addAlpha(brand_accent_color, 0.25),
      },
      color: props.isSelected ? DefaultLight : props.isFocused ? brand_accent_color : DefaultDark,
    }),
    indicatorSeparator: (
      base: CSSObjectWithLabel,
      props: IndicatorSeparatorProps<any, any, any>,
    ) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (
      base: CSSObjectWithLabel,
      props: DropdownIndicatorProps<any, any, any>,
    ) => ({
      ...base,
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
      },
    }),
    singleValue: (base: CSSObjectWithLabel, props: SingleValueProps<any>) => {
      const opacity = props.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'
      if (props.hasValue) {
        return { ...base, opacity, transition, color: '#ffffff' }
      }
      return { ...base, opacity, transition, color: brand_accent_color }
    },
    placeholder: (base: CSSObjectWithLabel, props: PlaceholderProps<any, any, any>) => {
      const opacity = props.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'
      return {
        ...base,
        opacity,
        transition,
        color: 'inherit',
      }
    },
    multiValue: (base: CSSObjectWithLabel) => ({
      ...base,
      borderRadius: '3.125rem',
    }),
    multiValueRemove: (base: CSSObjectWithLabel) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      borderRadius: '3.125rem',
    }),
  }
}

interface ValueOption<T = any> {
  value?: T
  label: string
}

type SelectPropsExtended = Omit<
  Omit<Omit<Props<ValueOption, false>, 'value'>, 'onChange'>,
  'onChange'
>

interface IProps<T = any> extends SelectPropsExtended {
  value?: T
  placeholder?: string
  options?: ValueOption<T>[]
  program: ExploreProgram
  onChange?: (value?: T) => void | Promise<void>
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void | Promise<void>
}

export const FilterSelect = ({
  value,
  program,
  placeholder = 'All',
  options = [],
  onChange: handleChange,
  ...props
}: IProps) => {
  const option = options.find((o) => o.value === value)
  return (
    <Select
      {...props}
      className={[props.className, 'react-select--inline'].filter(Boolean).join(' ')}
      classNamePrefix="react-select"
      value={option || null}
      options={options}
      placeholder={placeholder}
      isSearchable={false}
      styles={getCustomStyles(program.preferences)}
      onChange={(option: SingleValue<ValueOption>) => {
        handleChange?.(option?.value)
      }}
    />
  )
}
