import { useRouter } from 'next/router'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'
import { ErrorResponse, ExploreApi, QuickFilterList, QuickFilter } from '@citruscamps/citrus-client'
import { DefaultSearchPagination, UnlimitedPagination } from '../../../constants/pagination'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { Pagination } from '../../../interfaces/pagination'
import { generateApiConfig } from '../../../utils/client-config'
import { generateItemKey, generateListKey } from '../../../utils/key-generator'
import { useRequestHandler } from '../../../hooks/useRequestHandler'

export const DefaultFetchProps: QuickFiltersFetchProps = {
  sort: undefined,
  order: undefined,
}

interface QuickFiltersFetchProps {
  sort?: string
  order?: 'ASC' | 'DESC'
}

interface IProps {
  programId: string
  pagination?: Pagination
  fetchProps?: QuickFiltersFetchProps
  overwriteFilters?: boolean
  enabled?: boolean
  fetchAll?: boolean
}

interface FetchQuickFilters {
  data: QuickFilter[]
  error?: ErrorResponse
  isError: boolean
  isLoading: boolean
}

export const useFetchQuickFilters = ({ programId }: IProps): FetchQuickFilters => {
  const { requestHandler } = useRequestHandler()
  const queryKeys = generateItemKey({
    type: 'quick_filter',
    id: programId,
  })
  const {
    isInitialLoading: isLoading,
    isError,
    data: resp,
    error,
  } = useQuery<QuickFilterList, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new ExploreApi(generateApiConfig())
      return await requestHandler(() =>
        client.findFiltersExploreProgram({ program_id: programId }, { signal }),
      )
    },
    {
      enabled: !!programId,
    },
  )

  return {
    data: resp?.data || [],
    error: error || undefined,
    isError,
    isLoading,
  }
}

interface FetchInfiniteQuickFilters {
  data: QuickFilter[]
  error?: ErrorResponse | null
  isError: boolean
  isLoading: boolean
  fetchProps: QuickFiltersFetchProps
  hasNextPage: boolean
  isFetchingNextPage: boolean
  pagination: Pagination
  isFetching: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<QuickFilterList, ErrorResponse>>
  setFetchProps: (value?: QuickFiltersFetchProps) => void
}

export const useFetchInfiniteQuickFilters = ({
  programId,
  pagination = DefaultSearchPagination,
  overwriteFilters,
  enabled = true,
  fetchAll = false,
  ...props
}: IProps): FetchInfiniteQuickFilters => {
  const { requestHandler } = useRequestHandler()
  const { asPath } = useRouter()
  const initialFetchProps: QuickFiltersFetchProps = props.fetchProps || DefaultFetchProps
  const [fetchProps, setFetchProps] = useLocalStorage<QuickFiltersFetchProps>(
    `quick-filter-${asPath}`,
    initialFetchProps,
    overwriteFilters,
  )
  const queryKeys = generateListKey({
    type: 'quick_filter',
    pagination: UnlimitedPagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { program_id: programId },
  })
  const {
    data: resp,
    error,
    isInitialLoading: isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage = false,
  } = useInfiniteQuery<QuickFilterList, ErrorResponse>(
    queryKeys,
    async ({ signal, pageParam: pg }) => {
      pg = pg || pagination
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new ExploreApi(generateApiConfig())
      const skip = pg.limit ? pg.page * pg.limit : undefined
      const resp = await requestHandler<QuickFilterList>(() =>
        client.findFiltersExploreProgram(
          {
            program_id: programId,
            limit: pg.limit,
            skip,
            order: fetchProps.order || undefined,
            sort: fetchProps.sort || undefined,
          },
          { signal },
        ),
      )
      return resp
    },
    {
      getNextPageParam: (resp: QuickFilterList): Pagination | undefined => {
        if (!resp.page_count || resp.page < resp.page_count - 1) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page + 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      getPreviousPageParam: (resp: QuickFilterList): Pagination | undefined => {
        if (!resp.page_count || resp.page <= resp.page_count) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page - 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      enabled: !!programId && !!enabled,
    },
  )
  const _setFetchProps = (val?: QuickFiltersFetchProps) => {
    return setFetchProps(val || DefaultFetchProps)
  }

  if (!(isFetching || isFetchingNextPage || isLoading) && hasNextPage && fetchAll) {
    fetchNextPage()
  }

  const response = [...(resp?.pages || [])].pop()

  return {
    data: (resp?.pages || []).reduce<QuickFilter[]>((list, p) => [...list, ...p.data], []) || [],
    error,
    fetchProps,
    hasNextPage,
    isError,
    isFetching,
    isFetchingNextPage,
    isLoading,
    pagination: {
      ...pagination,
      count: response?.count || pagination.count,
      total: response?.total || pagination.total,
      page: response?.page || pagination.page,
      page_count: response?.page_count || pagination.page_count,
    },
    fetchNextPage,
    setFetchProps: _setFetchProps,
  }
}
