import React from 'react'
import { Venue, QuickFilter, FilterQueryValues } from '@citruscamps/citrus-client'
import { EventFilterProps } from '../../../hooks/useFetchEvents'

const DefaultSelects: EventFilterProps = {
  type: 'camp',
  place_name: undefined,
  age: undefined,
  gender: undefined,
  is_online_event: undefined,
  tags: [],
  scheduled: {},
}

const doesMatchVenue = (placeName: string, venue: Venue) =>
  venue.place_name?.toLowerCase().includes(placeName.toLowerCase()) ||
  venue.city?.toLowerCase().includes(placeName.toLowerCase()) ||
  venue.region?.toLowerCase().includes(placeName.toLowerCase())

interface IProps {
  values: EventFilterProps
  venue: Venue | undefined
  quickFilters: QuickFilter[]
  isLoading: boolean
  onChange: (values: EventFilterProps) => Promise<void> | void
}

export const QuickFilterSelect = ({
  values,
  venue,
  quickFilters,
  isLoading,
  onChange: handleChange,
}: IProps) => {
  const isSelected = (query: FilterQueryValues): boolean => {
    return Object.keys(query).reduce((active: boolean, key: string): boolean => {
      switch (key) {
        case 'venue':
          return (
            active &&
            ((!!venue?.city && !!query.venue && doesMatchVenue(venue.city, query.venue)) ||
              (!venue && !query.venue))
          )
        case 'tags':
          return active && JSON.stringify(query.tags || []) === JSON.stringify(values.tags || [])
        case 'online_event':
          return active && query['online_event'] === values['is_online_event']
        default:
          return (
            active &&
            query[key as keyof FilterQueryValues] === values[key as keyof EventFilterProps]
          )
      }
    }, true)
  }

  const hasSelected = quickFilters.some((f) =>
    isSelected({ ...f.query, type: f.query.type || 'camp' }),
  )
  return (
    <ul
      className="nav pb-3 d-flex justify-content-lg-start justify-content-md-start justify-content-center"
      style={{ marginTop: '-1.5rem', minHeight: isLoading ? 65 : undefined }}
    >
      <li className="nav-item px-3">
        <button
          className={`nav-link pb-1 px-0 ${!hasSelected ? 'active border-bottom' : ''}`}
          onClick={() => {
            handleChange({
              ...DefaultSelects,
              place_name: undefined,
            })
          }}
        >
          All
        </button>
      </li>
      {quickFilters.map((filter) => (
        <li className="nav-item px-3" key={filter.id}>
          <button
            className={`nav-link pb-1 px-0 ${
              isSelected({ ...filter.query, type: filter.query.type || 'camp' })
                ? 'active border-bottom'
                : ''
            }`}
            onClick={() => {
              handleChange({
                ...DefaultSelects,
                ...filter.query,
                place_name:
                  filter.query.online_event || !filter.query.venue
                    ? undefined
                    : filter.query.venue?.city || values.place_name,
                is_online_event: filter.query.online_event,
              })
            }}
          >
            {filter.name}
          </button>
        </li>
      ))}
    </ul>
  )
}
